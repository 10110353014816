<template>
  <section>
    <div class="container-fluid w-100 h-100" :class="{'skeleton-box': !property}">
      <div class="d-flex align-items-center flex-wrap justify-content-around flex-grow-1" :class="{'skeleton-box-hide': property}">
        <!-- Position -->
        <div class="donut-container my-3 flex-grow-1">
          <vc-donut
            background="white" foreground="grey"
            :size="150" unit="px" :thickness="40"
            has-legend legend-placement="bottom"
            :sections="sections" :total="100"
            :start-angle="30" :auto-adjust-text-size="true">
            <!-- <h6>100%</h6> -->
          </vc-donut>
        </div>
        <div class="row justify-content-between my-3 flex-grow-1">
        <!-- Purchase amount -->
        <SquareBlock class="col-sm-12 col-md-6 col-lg-6 my-3" :title="`${readablePurchaseAmount}€`" subtitle="Valor da compra"></SquareBlock>
        <!-- Patrimonial value -->
        <SquareBlock class="col-sm-12 col-md-6 col-lg-6 my-3" :title="`${readableEquityValue}€`" subtitle="Valor patrimonial"></SquareBlock>
        </div>
      </div>
    </div>
    
  </section>
</template>
<script>
import SquareBlock from "@/controls/SquareBlock.vue";

  export default {
    props:
    {
      property: Object
    },
    components: { SquareBlock },
    computed:{
      liquid: function()
      {
        return this.property ? this.property.accountability : 0;
      },
      readableLiquid: function()
      {
        return this.formatNumber(this.liquid)
      },
      percentLiquid : function() {
        return this.property ? (this.property.accountability * 100) / this.property.totalFinancing : 0;
      },
      nonLiquid : function () {
        return this.property ? this.property.totalFinancing - this.property.accountability : 0;
      },
      readableNonLiquid: function()
      {
        return this.formatNumber(this.nonLiquid)
      },
      readablePurchaseAmount: function()
      {
        return this.formatNumber(this.property.purchaseAmount)
      },
      readableEquityValue: function()
      {
        return this.formatNumber(this.property.equityValue)
      },
      percentNonLiquid : function () {
        return this.percentLiquid > 0 ? 100 - this.percentLiquid : 0;
      },
      sections: function()
      {
        var result = [
          { label: `Liquidado: ${this.readableLiquid}€ (${this.percentLiquid.toFixed(2)}%)`, value: this.percentLiquid, color: 'rgb(78, 84, 200)' },
          { label: `Por liquidar: ${this.readableNonLiquid}€ (${this.percentNonLiquid.toFixed(2)}%)`, value: this.percentNonLiquid, color: 'rgb(143, 148, 251)' },
        ];
        return result;
      } 
    },
    methods:{
      formatNumber(value)
        {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } 
    },
  };
</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}

/* h5{
  margin-left: 1rem;
} */

.skeleton-box
{
  min-height: 25vh;
}

  .col-lg-6 .donut-container
  {
    width: 100%;
  }

@media only screen and (max-width: 991px) {
  .skeleton-box
  {
    min-height: 80vh;
  }
}

>>>.cdc-legend
{
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>