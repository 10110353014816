<template>
    <div>
        <center class="center">
            <div class="square">
                <div>
                    <img v-if="icon" :src="require('@/assets/' + icon)">
                    <h6 class="text-light mt-2">{{title}}</h6>
                    <span v-if="subtitle" class="text-light">{{subtitle}}</span>
                </div>
            </div>
        </center>
    </div>
</template>

<script>
export default {
    props:
    {
        icon: String,
        title: String,
        subtitle: String
    }
}
</script>

<style scoped>
.square{
  border: 4px solid rgb(78, 84, 200);
  background-color: rgb(143, 148, 251);
  border-radius: 20px;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
img{
  width: 25px;
}

</style>