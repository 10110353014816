<template>
    <div class="dropdown">
        <div :id="'dropdown-menu-link-'+ index" class="dropdown-toggle px-2" v-bind:class="{'is-invalid': !isValid, 'no-selection': !selectedOption}" @click="dropdown()" role="button" data-toggle="dropdown" aria-expanded="false"><span>{{headingTitle}}</span></div>
        <div v-if="canClear" @click="clearClicked" class="clear-area"></div>
        <div class="dropdown-menu px-1" :ref="'dropdown-' + index" :aria-labelledby="'dropdown-menu-link'+ index">
            <a v-for="option in options" :key="option.key" class="dropdown-item" @click="optionClick(option)">{{option.label}}</a>
        </div>     
    </div>
</template>
<script>
export default {
    data(){
        return{
            selectedOption: null
        }
    },
    props:{
        value:
        {
            type: String,
            default: null
        },
        index: Number,
        options:
        {
            type: Array,
            default: () => []
        },
        title:
        {
            type: String,
            default: ""
        },
        canClear:
        {
            type: Boolean,
            default: false
        },
        isValid:
        {
            type: Boolean,
            default: true
        }
    },
    computed:
    {
        headingTitle: function()
        {
            var result = this.title;

            if(this.selectedOption)
            {
                result = this.selectedOption.label;
            }

            return result;
        }
    },
    mounted: function()
    {
        if(this.value)
        {
            var defaultValue = this.value;
            this.selectedOption = this.options.find(obj => {
                return obj.key === defaultValue
            });
        }

        document.addEventListener('click', this.handleClickOutside);
    },
    methods:{
        clearClicked()
        {
            this.selectedOption = null;
        },
        dropdown() {
            // Note that for date clicks are handled by the input
            this.$refs['dropdown-'+this.index].classList.toggle('showDropdown');
        },
        optionClick(option){
            this.selectedOption = option;
            this.$refs['dropdown-'+this.index].classList.remove("showDropdown");
        },
        handleClickOutside(evt) {
            if (!this.$el.contains(evt.target)) {
                if(this.$refs['dropdown-'+this.index] !== undefined)
                {
                    this.$refs['dropdown-'+this.index].classList.remove("showDropdown");
                }
            }
        }
    },
    watch:
    {
        value: function(val)
        {
            if(this.options && this.options.length)
            {
                this.selectedOption = this.options.find(obj => {
                    return obj.key === val
                });
            }
        },
        selectedOption: function(val)
        {
            var value = val ? val.key : null;
            this.$emit('input', value);
        },
        options: function(val)
        {
            if(this.value && val && val.length)
            {
                const value = this.value;
                this.selectedOption = val.find(obj => {
                    return obj.key === value
                });
            }
        }
    }  
}
</script>

<style scoped>
.no-selection
{
    color: #212529;
}
.clear-area
{
    position: absolute;
    right: 0;
    width: 40px;
    height: 100%;
    top: 0;
    cursor: pointer;
}

.dropdown-clear::after
{
    content: "x";
    display: contents;
    font-weight: bold;
}

.dropdown-toggle
{
    min-width: 125px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-color: #d8dbe0;
    min-height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #d8dbe0;
    border-radius: 0.25rem;
}

.dropdown-toggle.is-invalid
{
    border-color: #e55353;
}

.dropdown-toggle.is-invalid::after
{
    color: #e55353;
}

.in-front
{
    z-index: 999;
}

.showDropdown
{
    display: block;
}

</style>