<template>
  <section>
    <div class="container-fluid d-flex w-100 h-100" :class="{'skeleton-box': !propertyDetails}">
      <div class="row align-items-center flex-wrap justify-content-center" :class="{'skeleton-box-hide': propertyDetails}">
        <!-- Title -->
        <h5 class="secondary-text-color flex-grow-1">MÓDULOS DO IMÓVEL</h5>
        <!-- Status overview -->
        <div class="row justify-content-center my-3">
          <SquareBlock class=" col-sm-12 col-md-6 col-lg-6 my-3" icon="dashboard/iconHome.png" :title="readableNumIncome" subtitle="Alugado, AirBnB ou outro"></SquareBlock>
          <SquareBlock class="col-sm-12 col-md-6 col-lg-6 my-3" icon="dashboard/iconHome.png" :title="readableNoIncome" subtitle="Sem rendimentos"></SquareBlock>
        </div>
        <div>
          <div id="property-status-title" class="d-flex">
            <!-- Title -->
            <h5 class="secondary-text-color flex-grow-1">CASH FLOWS</h5>
            <!-- Actions -->
            <div class="actions-container d-flex flex-wrap justify-content-end">
              <a class="primary-text-color" @click="$emit('add-requested')">Registar cash flow</a>
              <a class="primary-text-color" @click="$emit('schedule-requested')">Ver calendarizados</a>
            </div>
          </div>
          <!-- Details -->
          <div v-if="propertyDetails" id="property-status-details" class="d-flex flex-wrap justify-content-around align-items-center">
              <SquareBlock class="my-3" :title="readableTotalCash" subtitle="Valor total"></SquareBlock>
              <div class="d-flex flex-wrap">
                  <!-- Income -->
                <div class="d-flex flex-column align-items-center m-3">
                    <span class="font-weight-bold">{{readableIncome}}€</span>
                    <span class="secondary-text-color font-weight-bold">Rendas</span>
                  </div>
                  <!-- Accountability -->
                <div class="d-flex flex-column align-items-center m-3">
                    <span class="font-weight-bold">{{readableAccountability}}€</span>
                    <span class="secondary-text-color font-weight-bold">Prestações</span>
                  </div>
                <!-- Costs -->
                <div class="d-flex flex-column align-items-center m-3">
                  <span class="font-weight-bold">{{readableCosts}}€</span>
                  <span class="secondary-text-color font-weight-bold">Custos</span>
                </div>
              </div>
          </div>
          <!-- View history action-->
          <div class="d-flex flex-wrap justify-content-end">
            <a class="history-text" @click="$emit('history-requested')">Ver histórico</a>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>
<script>
import SquareBlock from "@/controls/SquareBlock.vue";

  export default {
    props:
    {
      propertyDetails: Object
    },
    components: { SquareBlock },
    computed:{
      numIncome: function()
      {
        return this.propertyDetails ? (this.propertyDetails.rented + this.propertyDetails.airBnB).toString() : "0";
      },
      readableNumIncome: function()
      {
        return this.formatNumber(this.numIncome)
      },
      numNoIncome: function()
      {
        return this.propertyDetails ? this.propertyDetails.noIncome.toString() : "0";
      },
      readableNoIncome: function()
      {
        return this.formatNumber(this.numNoIncome)
      },
      totalCash: function()
      {
        return this.propertyDetails ? (this.propertyDetails.cashFlow.income - this.propertyDetails.cashFlow.accountability - this.propertyDetails.cashFlow.costs - this.propertyDetails.cashFlow.initialCost).toString() : "0";
      },
      readableTotalCash: function()
      {
        return this.formatNumber(this.totalCash)
      },
      readableIncome: function()
      {
        return this.formatNumber(this.propertyDetails.cashFlow.income)
      },
      readableAccountability: function()
      {
        return this.formatNumber(this.propertyDetails.cashFlow.accountability)
      },
      readableCosts: function()
      {
        return this.formatNumber(this.propertyDetails.cashFlow.costs + this.propertyDetails.cashFlow.initialCost)
      },

    },
    methods:{
      formatNumber(value)
        {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } 
    },
  };
</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}
#property-status-title,
#property-status-details > div
{
  gap: 8px;
}
.skeleton-box
{
  min-height: 25vh;
}

.actions-container
{
  gap: 0 2rem;
}
.history-text{
  font-size: .7rem;
  color: gray;
}
@media only screen and (max-width: 991px) {
  .skeleton-box
  {
    min-height: 80vh;
  }
}
@media only screen and (max-width: 576px ) {
 #property-status-details{
   flex-direction: column;
 }
.m-3 {
    margin: 0.2rem !important;  
  }
}
</style>