<template>
        <div class="container  py-5 d-flex flex-column w-100" v-if="property">
            <!-- Header (name, ownership, button to edit, purchase date) -->
            <div id="header" class="d-flex flex-wrap align-items-center">
                <!-- Name -->
                <h3 class="primary-text-color font-weight-bold">{{property.name}}</h3>
                <!-- Ownership -->
                <span class="primary-text-color font-weight-bold">({{property.ownership == 'Personal' ? "Pessoal" : "Empresa"}})</span>
                <!-- Button to edit -->
                <a id="edit-button" class="primary-text-color flex-grow-1" @click="$router.push({ name: 'EditProperty', params: { property: property, modules: modules } })">Editar detalhes</a>
                <!-- Purchase edit -->
                <span>Comprado a <span class="primary-text-color font-weight-bold">{{property.purchaseDate}}</span></span>
            </div>
            <!-- Address -->
            <p>{{property.address}}</p>
                <!-- Year view -->
                <h5 class="secondary-text-color">Anos financiamento</h5>
                <GraphSlider ref="graphSlider" class="mt-5" primaryColor="#4e54c8" labelColor="#4e54c8" holderColor="#FFFFFF" gridTextColor="#000000" :lineHeight="30" :min="0" :max="property.financingYears" :handleSize="0" :fromFixed="true" :toFixed="true" :forceEdges="true" :hideMinMax="false" :grid="false" type="single" :data="[0, property.financingYears]"/>
            <div v-if="!loading">   
                <!-- Property finances and module overview (if applicable) -->
                <div class="row flex-wrap mt-5 justify-content-between">
                    <PropertyFinances class="col-12 my-3" :class="{ 'col-lg-6': modules.length > 1 }" :property="property"></PropertyFinances>
                    <PropertyOverview v-if="modules.length > 1" class="col-12 col-lg-6 my-3" :propertyDetails="propertyDetails" @add-requested="showRegisterCashFlow = property" @history-requested="isCashFlowListRecord = true; showCashFlowList = property" @schedule-requested="isCashFlowListRecord = false; showCashFlowList = property"></PropertyOverview>
                </div>
                <!-- Information per module -->
                <CTabs id="modules-info" variant="tabs" :class="{'single-element': modules.length == 1}" :active-tab.sync="activeModuleTab">
                    <CTab v-for="module in modules"  :class="{'single-element': modules.length == 1}" :key="module.id" :title="module.name">
                        <div v-if="moduleDetails" class="row flex-wrap justify-content-between">
                            <ModuleStatus @change-requested="showChangeStatus = true" @history-requested="showStatusHistory = true" :module="moduleDetails" class="col-12 col-lg-6 my-3"></ModuleStatus>
                            <ModuleCashFlows :moduleDetails="moduleDetails" class="col-12 col-lg-6 my-3" @add-requested="showRegisterCashFlow = moduleDetails" @history-requested="isCashFlowListRecord = true; showCashFlowList = moduleDetails" @schedule-requested="isCashFlowListRecord = false; showCashFlowList = moduleDetails"></ModuleCashFlows>
                        </div>
                    </CTab>
                </CTabs>
                <AlertList v-if="listOutput && modules.length" :moduleId="currentModule" class="col-12 my-3"></AlertList>

                <span v-if="listOutput && modules.length == 0">Ainda não tem módulos associados ao imóvel (apartamentos, lojas,...). Por favor aceda a "Editar detalhes" e faça a gestão dos módulos.</span>
                
                <!-- Status Pop ups -->
                <ChangeModuleStatusPopUp v-if="currentModule" :moduleName="currentModule.name" :moduleId="currentModule.id" @closeConfirmRequested="showChangeStatus = false" v-model="showChangeStatus"></ChangeModuleStatusPopUp>
                <StatusHistoryPopUp v-if="currentModule" :moduleDetails="moduleDetails" @closeConfirmRequested="showStatusHistory = false" v-model="showStatusHistory"></StatusHistoryPopUp>
                <!-- Cash flow pop ups -->
                <RegisterCashFlowPopUp v-if="showRegisterCashFlow" :details="showRegisterCashFlow" @closeConfirmRequested="showRegisterCashFlow = false" v-model="showRegisterCashFlow"></RegisterCashFlowPopUp>
                <ModuleCashFlowsPopUp v-if="showCashFlowList" :details="showCashFlowList" @closeConfirmRequested="showCashFlowList = false" v-model="showCashFlowList" :isRecord="isCashFlowListRecord"></ModuleCashFlowsPopUp>
            </div>
        <!-- <SkeletonLoading v-else class=" my-5 w-100"></SkeletonLoading> -->
            <div v-if="loading" class="d-flex flex-wrap mt-5 justify-content-between">
                <SkeletonLoading v-for="(block, index) in (new Array(blockItems))" :key="index"></SkeletonLoading>
            </div>

        </div>
    
</template>

<script>
import { mapState, mapActions } from 'vuex'
import PropertyFinances from "@/views/properties/PropertyFinances.vue";
import PropertyOverview from "@/views/properties/PropertyOverview.vue";
import ModuleStatus from "@/views/properties/ModuleStatus.vue";
import AlertList from "@/views/properties/AlertList.vue";
import ModuleCashFlows from "@/views/properties/ModuleCashFlows.vue";
import ChangeModuleStatusPopUp from "@/controls/popups/ChangeModuleStatusPopUp.vue";
import StatusHistoryPopUp from "@/controls/popups/StatusHistoryPopUp.vue";
import RegisterCashFlowPopUp from "@/controls/popups/RegisterCashFlowPopUp.vue";
import ModuleCashFlowsPopUp from "@/controls/popups/ModuleCashFlowsPopUp.vue";
import SkeletonLoading from "@/controls/SkeletonLoading.vue"

const DEFAULT_ITEMS = 4;

export default {  
    data () {
        return {
            property: null,
            activeModuleTab: 0,
            //
            blockItems: DEFAULT_ITEMS,
            showChangeStatus: false,
            showStatusHistory: false,
            showRegisterCashFlow: false,
            showCashFlowList: false,
            isCashFlowListRecord: false,
        }
    },
    components: { PropertyFinances, PropertyOverview, ModuleStatus, ModuleCashFlows, ChangeModuleStatusPopUp, StatusHistoryPopUp, RegisterCashFlowPopUp, ModuleCashFlowsPopUp, AlertList, SkeletonLoading },
    computed:
    {
        ...mapState('modules', ['listOutput']),
        ...mapState('modules', ['moduleOutput']),
        ...mapState('modules', ['processTask']),
        ...mapState('properties', ['propertyOutput']),
        loading: function()
        {
            // return true
            return this.listOutput == null || this.moduleOutput == null || this.propertyOutput == null
        },
        yearsSincePurchase: function()
        {
            const purchaseDate = new Date(this.property.purchaseDate);

            var difMs = Date.now() - purchaseDate;
            var date = new Date(difMs); // miliseconds from epoch

            return Math.abs(date.getUTCFullYear() - 1970);
        },
        modules: function()
        {
            return this.listOutput && this.listOutput.data && this.listOutput.data.modules ? this.listOutput.data.modules : [];
        },      
        moduleDetails: function()
        {
            return this.moduleOutput && this.moduleOutput.data ? this.moduleOutput.data : null;
        },
        propertyDetails: function() 
        {
            return this.propertyOutput && this.propertyOutput.data ? this.propertyOutput.data : null;
        },
        currentModule: function()
        {
            return this.modules.length ? this.modules[this.activeModuleTab] : null;
        }
    },
    methods: {
        ...mapActions('properties', ['editProperty']),
        ...mapActions('properties', ['getProperty']),
        ...mapActions('modules', ['getList']),
        ...mapActions('modules', ['getModule'])
    },
    mounted: function()
    {
        // Validate parameter (the property needs to be passed to the view)
        if(!this.$route.params.property)
        {
            this.$router.push({ name: 'PropertiesList' });
        }
        else
        {
            this.property = this.$route.params.property;

            // Get property details
            this.getProperty(this.property.id);
            
            const currentPage = 1;
            const itemsPerPage = 999;
            const propertyName = 'id';
            // Get modules details
            const params = { currentPage, itemsPerPage,propertyName, propertyId: this.property.id, active: true};
            this.getList(params);

            // Setup financing years
            this.$nextTick(()=>{
                this.$refs.graphSlider.update({ from: this.yearsSincePurchase });
            });
        }
    },
    watch:
    {
        listOutput: function(val)
        {
            if(val && this.modules.length)
            {
                this.getModule(this.currentModule.id);
            }
        },
        processTask: function(val)
        {
            if(val)
            {
                this.getModule(this.currentModule.id);                
                this.showChangeStatus = false;
            }
        },
        activeModuleTab: function()
        {
            if(this.modules.length)
            {
                this.getModule(this.currentModule.id);
            }
        },
        currentModule: function(val)
        {
            if (val) {
                // Function created to send the moduleId to the prop created in the AlertList
                return val.id;
            }        
        }
    }
}
</script> 

<style scoped>
#header
{
    gap: 8px;
}

/* Modules info */
>>>#modules-info.single-element .nav
{
    display: none; /* Hide tabs if there is only one element */
}

/* Slider */
#vue-graph-slider-wrapper
{
    width:100% !important;
    pointer-events:none;
}

>>>#vue-graph-view
{
    display: none;
}

>>>.irs--round .irs-bar--single
{
    border-radius: 35px 0 0 35px;
}

>>>.nav-link{
  color: rgb(78, 84, 200) !important;
}
.skeleton-box{
    height: 535px !important;
    width: 615px !important;
    background-color: white !important;
    margin: 30px auto;
    border-radius: 20px;
}
@media (max-width: 1600px) {
    .skeleton-box{
        width: 500px!important;
    }
}
@media (max-width: 1350px) {
.skeleton-box{
    width: 400px!important;
    }
}
@media (max-width: 1150px) {
.skeleton-box{
    width: 320px!important;
    }
}
@media (max-width: 991px) {
    .skeleton-box{
        width: 700px!important;
    }
}
</style>