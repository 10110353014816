export default class InputNewStatus
{
  constructor(moduleId, status, personName, personEmail, personPhoneNumber, url) 
  {
    this.moduleId = moduleId;
    this.status =  status;
    this.personName =  personName;
    this.personEmail =  personEmail;
    this.personPhoneNumber =  personPhoneNumber;
    this.url =  url;
  }    
}