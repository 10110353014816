export default class InputGetCashFlows
{
  constructor(pageSize, pageNumber, orderBy, propertyName, searchText, destination, destinationId) 
  {
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
    this.orderBy = orderBy;
    this.propertyName = propertyName;
    this.searchText = searchText;
    this.destination = destination;
    this.destinationId = destinationId;
  }    
}