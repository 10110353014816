<template>
  <section>
    <div class="container-fluid d-flex w-100 h-100" :class="{'skeleton-box': !moduleDetails}">
      <div class="row align-items-center flex-wrap justify-content-between" :class="{'skeleton-box-hide': moduleDetails}">
        <div id="module-status-title" class="d-flex">
          <!-- Title -->
          <h5 class="secondary-text-color flex-grow-1">CASH FLOWS</h5>
          <!-- Actions -->
          <div class="actions-container d-flex flex-wrap justify-content-end">
            <a class="primary-text-color" @click="$emit('add-requested')">Registar cash flow</a>
            <a class="primary-text-color" @click="$emit('schedule-requested')">Ver calendarizados</a>
          </div>
        </div>
        <!-- Details -->
        <div v-if="moduleDetails" id="module-status-details" class="d-flex justify-content-around align-items-center">
          <SquareBlock class="my-3 mx-2" :title="readableTotalCash" subtitle="Valor total"></SquareBlock>
          <div class="d-flex flex-wrap">
            <!-- Rendas -->
            <div class="d-flex flex-column align-items-center m-3">
              <span class="font-weight-bold">{{readableIncome}}€</span>
              <span class="secondary-text-color font-weight-bold">Rendas</span>
            </div>
            <!-- Costs -->
            <div class="d-flex flex-column align-items-center m-3">
              <span class="font-weight-bold">{{readableCosts}}€</span>
              <span class="secondary-text-color font-weight-bold">Custos</span>
            </div>
          </div>
        </div>
        <!-- View history action-->
        <div class="d-flex flex-wrap justify-content-end">
          <a class="history-text" @click="$emit('history-requested')">Ver histórico</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SquareBlock from "@/controls/SquareBlock.vue";

  export default {
    props:
    {
      moduleDetails: Object
    },
    components: { SquareBlock },
    computed:
    {
      totalCash: function()
      {
        return this.moduleDetails ? (this.moduleDetails.cashFlow.income - this.moduleDetails.cashFlow.costs).toString() : "0";
      },
      readableTotalCash: function()
        {
          return this.formatNumber(this.totalCash)
        },
      readableIncome: function()
        {
          return this.formatNumber(this.moduleDetails.cashFlow.income)
        },
      readableCosts: function()
        {
          return this.formatNumber(this.moduleDetails.cashFlow.costs)
        },
    },
    methods:{
      formatNumber(value)
        {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } 
    },
  };
</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}
#module-status-title,
#module-status-details > div
{
    gap: 8px;
}
.skeleton-box
{
  min-height: 25vh;
}
.actions-container
{
  gap: 0 2rem;
}
.history-text{
  font-size: .7rem;
  color: gray;
}
@media only screen and (max-width: 991px) {
  .skeleton-box
  {
    min-height: 80vh;
  }
}
@media only screen and (max-width: 576px ) {
 #module-status-details{
   flex-direction: column;
 }
}
</style>