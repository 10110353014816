<template>
  <section>
    <div class="container-fluid d-flex w-100 h-100" :class="{'skeleton-box': !module}">
      <div class="row align-items-center flex-wrap justify-content-between" :class="{'skeleton-box-hide': statusDetailsHtml}">
        <div id="module-status-title" class="d-flex">
            <!-- Title -->
          <h5 class="secondary-text-color flex-grow-1">ESTADOS</h5>
            <!-- Actions -->
          <div class="actions-container d-flex flex-wrap justify-content-end">
              <a class="primary-text-color" @click="$emit('change-requested')">Alterar</a>
          </div>
        </div>
        <!-- Details -->
        <div v-if="statusDetailsHtml"  id="module-status-details" class="d-flex justify-content-around align-items-center">
          <SquareBlock :icon="statusIcon" class="my-3 mx-2" :title="statusName"></SquareBlock>
          <div v-html="statusDetailsHtml" class="d-flex flex-wrap"/>        
        </div>
        <!-- View history action-->
        <div class="d-flex flex-wrap justify-content-end">
          <a class="history-text" @click="$emit('history-requested')">Ver histórico</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SquareBlock from "@/controls/SquareBlock.vue";

  export default {
    props:
    {
      module: Object
    },
    components: { SquareBlock },
    computed:
    {
        lastStatus: function()
        {
          return this.module && this.module.statuses.length ? this.module.statuses[0] : null;
        },
        statusIcon: function()
        {
          const value = this.lastStatus ? this.lastStatus.status : null;
          switch(value)
          {
            case "Rented":
            {
                return "dashboard/iconHome.png";
            }
            case "AirBnB":
            {
                return "dashboard/iconHome.png";
            }
            default:
            {
                return "dashboard/iconHome.png";
            }
          }
        },
        statusName: function()
        {
          const value = this.lastStatus ? this.lastStatus.status : null;
          switch(value)
          {
            case "Rented":
            {
                return "Arrendado";
            }
            case "AirBnB":
            {
                return "AirBnb (ou similar)";
            }
            default:
            {
              return "Sem rendimento";
            }
          }
        },
        statusDetailsHtml: function()
        {
          const value = this.lastStatus ? this.lastStatus.status : null;
          switch(value)
          {
            case "Rented":
            {
                return `<div class="d-flex flex-wrap"><div class="d-flex flex-column align-items-center m-3"><span>${this.lastStatus.personName}</span><a href="tel:${this.lastStatus.personPhoneNumber}">${this.lastStatus.personPhoneNumber}</a><a href="mailto:${this.lastStatus.personEmail}">${this.lastStatus.personEmail}</a></div></div>`;
            }
            case "AirBnB":
            {
                return `><a>${this.lastStatus.url}</a>`;
            }
            default:
            {
              return `<div class="d-flex flex-wrap"><div class="d-flex flex-column align-items-center m-3"><span>O seu imóvel não tem qualquer rendimento.</span>`
            }
          }
        }
    }
  };
</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}
#module-status-title,
#module-status-details > div
{
    gap: 8px;
}
.skeleton-box
{
  min-height: 25vh;
}
.history-text{
  font-size: .7rem;
  color: gray;
}
@media only screen and (max-width: 991px) {
  .skeleton-box
  {
    min-height: 80vh;
  }
}
.actions-container
{
  gap: 0 2rem;
}
@media only screen and (max-width: 576px ) {
 #module-status-details{
   flex-direction: column;
 }
}


</style>