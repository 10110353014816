<template>
    <div class="container d-flex flex-column"> 
        <!-- Status -->         
        <div class="col-12 my-2">
            <label class="label-properties">Estado</label>
            <SingleOptionDropdown v-model="status" :options="statusOptions" :index="1" :is-valid="!submitted || (submitted && !hasError('Status'))"></SingleOptionDropdown>
            <div v-if="submitted && hasError('Status')" class="invalid-feedback">
                <span v-if="!$v.status.required">É necessário indicar o novo Estado</span>
                <span v-if="errors.Status">{{errors.Status}}</span>
            </div>
        </div>
        <!-- Person name -->
        <div v-if="isRented" class="col-12 my-2">
            <label class="label-properties">Nome da Pessoa</label>
            <input type="text" v-model="personName" class="form-control" :class="{ 'is-invalid': submitted && hasError('PersonName') }"/>
            <div v-if="submitted && hasError('PersonName')" class="invalid-feedback">
                <span v-if="!$v.personName.required">É necessário o Nome da Pessoa</span>
                <span v-if="errors.PersonName">{{errors.PersonName}}</span>
            </div>
        </div>
        <!-- Person email -->
        <div v-if="isRented" class="col-12 my-2">
            <label class="label-properties">Email da Pessoa</label>
            <input type="text" v-model="personEmail" class="form-control" :class="{ 'is-invalid': submitted && hasError('PersonEmail') }"/>
            <div v-if="submitted && hasError('PersonEmail')" class="invalid-feedback">
                <span v-if="!$v.personEmail.required">É necessário o Email da Pessoa</span>
                <span v-if="!$v.personEmail.email">Email Inválido</span>
                <span v-if="errors.PersonEmail">{{errors.PersonEmail}}</span>
            </div>
        </div>
        <!-- Person phone number -->
        <div v-if="isRented" class="col-12 my-2">
            <label class="label-properties">Número de telefone da Pessoa</label>
            <VuePhoneNumberInput default-country-code="PT" id="inputContactNumber" v-model="personPhoneNumber" :class="{ 'is-invalid': submitted && hasError('PersonPhoneNumber') }"/>
            <div v-if="submitted && hasError('PersonPhoneNumber')" class="invalid-feedback">
                <span v-if="!$v.personPhoneNumber.required">É necessário o Número de telefone da Pessoa</span>
                <span v-if="errors.PersonPhoneNumber">{{errors.PersonPhoneNumber}}</span>
            </div>
        </div>
        <!-- Url -->
        <div v-if="isAirbnb" class="col-12 my-2">
            <label class="label-properties">Url</label>
            <input type="text" v-model="url" class="form-control" :class="{ 'is-invalid': submitted && hasError('Url') }"/>
            <div v-if="submitted && hasError('Url')" class="invalid-feedback">
                <span v-if="!$v.url.required">É necessário o url do AirBnb (ou similar)</span>
                <span v-if="errors.Url">{{errors.Url}}</span>
            </div>
        </div>
        <!-- Action button + loading -->  
        <CSpinner v-if="loading" variant="success" small class="align-self-end mt-5"></CSpinner>
        <CButton v-else type="button" color="primary" class="btn align-self-end text-light mt-5" @click="submitClicked">Guardar</CButton>
    </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import { mapState, mapActions } from 'vuex'
import { required, requiredIf, email } from "vuelidate/lib/validators";
import Status from "@/classes/Status";
import SingleOptionDropdown from '@/controls/SingleOptionDropdown.vue'
import VuePhoneNumberInput from 'vue-phone-number-input';

const RENTED = "Rented";
const AIRBNB = "AirBnB";

export default {
    components: { SingleOptionDropdown, VuePhoneNumberInput },
    validations:
    {
        status: { required },
        personName: { required: requiredIf(function() { return this.isRented; }) },
        personEmail: { required: requiredIf(function() { return this.isRented;}), email },
        personPhoneNumber: { required: requiredIf(function() { return this.isRented; }) },
        url: { required: requiredIf(function() { return this.isAirbnb; }) }
    },
    data()
    {
        return {
            submitted: false,
            status: this.baseStatus ? this.baseStatus.status : '',
            personName: this.baseStatus ? this.baseStatus.personName : '',
            personEmail: this.baseStatus ? this.baseStatus.personEmail : '',
            personPhoneNumber: this.baseStatus ? this.baseStatus.personPhoneNumber : '',
            url: this.baseStatus ? this.baseStatus.url : '',
            //
            statusOptions: [{key: "Rented", label: "Arrendado"}, {key: "AirBnB", label: "AirBnb (ou similar)"}, {key: "NoIncome", label: "Sem rendimento"}]
        }
    },
    props:{
        moduleId: Number,
        baseStatus: Object
    },
    methods:{
        ...mapActions('modules', ['addStatus']),
        ...mapActions('modules', ['editStatus']),
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        submitClicked(){
            if(!this.moduleId)
            {
                throw "The module id is not defined!";
            }

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const params = new Status(this.moduleId, this.status, this.personName, this.personEmail, this.personPhoneNumber, this.url);

            if(this.baseStatus)
            {
                params.id = this.baseStatus.id;
                this.editStatus(params);
            }
            else
            {
                this.addStatus(params);
            }
        },
    },
    computed:
    {
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output,
        }),
        ...mapState('modules', ['processTask']),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        loading: function()
        {
            return this.processTask == null;
        },
        isRented: function()
        {
            return this.status == RENTED;
        },
        isAirbnb: function()
        {
            return this.status == AIRBNB;
        }
    }
}
</script>