<template>
    <BaseModal v-if="showModal" @detailsClosed="$emit('closeConfirmRequested')">
        <div v-if="!submitted" class="modal-body-container">
            <h4 class="text-center mt-4">Registar cash flow</h4>  
            <p class="text-center" v-if="details">{{details.name}}</p>
            <CashFlowEditor :details="details"></CashFlowEditor>
        </div>
        <div v-else class="modal-body-container">
            <h4 class="text-center mt-4">Registar cash flow</h4>  
            <p class="text-center">O cash flow foi registado com sucesso!</p>
            <CButton type="button" color="primary" class="mb-4 btn btn-primary align-self-center" @click="$emit('closeConfirmRequested')">Confirmar</CButton>
        </div>
    </BaseModal>
</template>

<script>
import { mapState } from 'vuex'
import BaseModal from './BaseModal.vue';
import CashFlowEditor from '@/views/properties/CashFlowEditor.vue'

export default {
    extends: BaseModal,
    components: { BaseModal, CashFlowEditor },
    props:{
        details: Object // Property or module
    },
    data() {
      return {  
        submitted: false
      }
    },  
    computed:
    {
        ...mapState('cashFlows', ['processTask']),
    },
    watch: 
    {
        processTask: function(val)
        {
            if(val)
            {
                this.submitted = true;
            }
        }
    }
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    }
</style>