<template>
    <BaseModal v-if="showModal" @detailsClosed="$emit('closeConfirmRequested')">
        <div class="modal-body-container">
            <h4 class="text-center mt-4">Atualizar estado</h4>  
            <p v-if="moduleName" class="text-center">{{moduleName}}</p>  
            <StatusEditor :moduleId="moduleId"></StatusEditor>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import StatusEditor from '@/views/properties/StatusEditor.vue'

export default {
    extends: BaseModal,
    components: { BaseModal, StatusEditor },
    props:{
        moduleName: String,
        moduleId: Number
    }
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    }
</style>