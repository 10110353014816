<template>
  <section>
    <div class="container-fluid w-100">
      <div class="row align-items-center flex-wrap justify-content-between">
        <!-- Title -->
        <div class="d-flex">
          <h5 class="secondary-text-color flex-grow-1">ALERTAS</h5>
          <a class="primary-text-color" @click="addAlert">Adicionar Alerta</a>
        </div>
        <div class="d-flex flex-column col-12">
          <CTableWrapper ref="table" class="my-5" hover striped :caption="caption" :items="items" :actions="actions" :pages="pages" :fields="fields" @update-requested="updateItemsRequested" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked"  :loading="loading" paginationSubtitle="Estados por página">
          </CTableWrapper>
        </div>
        <!-- List
        <div class="modal-body-container">
          <div class="d-flex flex-column px-4">  
          </div>
        </div> -->
      </div>
    </div>
  </section>
</template>
<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import CTableWrapper from '@/controls/PaginatedTable.vue'
import { mapState, mapActions } from 'vuex'
import router from '@/router/index';
import InputGetAlerts from "@/classes/InputGetAlerts";
import EventBus from '@/events/bus.js';

export default {
    components: { CTableWrapper },
    props:{ 
      moduleId: {
        type: Object,
        required: true
      }
    },
    data () {
        return {
            selectedEdit: null,
            //
            caption: "Lista de alertas",
            currentPage: 1,
            loading: false,
            itemsPerPage: localStorage.itemsPerPage ?? 10,
            fields: [
                { key: 'readableDate', label: ('Data'), _classes: 'font-weight-bold', sortable: false },
                { key: 'title', label: ('Título'), _classes: 'font-weight-bold', sortable: false },
                { key: 'content', label: ('Conteúdo'), _classes: 'font-weight-bold', sortable: false },
                { key: 'usersNames', label: ('Destinatários'), _classes: 'font-weight-bold', sortable: false },
            ],     
            actions: [
                { key: 'view', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' },
                { key: 'remove', image: 'iconCloseWhite.png', imageBig: 'iconClose.png' }
            ], 
        }
    },
    computed:
    {
      ...mapState('alerts', ['listOutput']),
      ...mapState('alerts', ['processTask']),
      alertsInfo: function() {
          return this.listOutput && this.listOutput.data ? this.listOutput.data : null;
      },
      items: function()
      {
      var result = [];

      if(this.alertsInfo && this.alertsInfo.alerts)
      {
          result = this.alertsInfo && this.alertsInfo.alerts;

          result.forEach(item => {
              item["readableDate"] = UTILS_HELPER.formatDateTime(item.moment);
              item["usersNames"] = item.users && item.users.length ? item.users.map(x=> x.name).join(", ") : "Todos";
          });
      }
      return result;
      },
      pages: function()
      {
        return this.listOutput ? this.listOutput.totalPages : 1;
      },
    },
    mounted(){
      this.updateItemsRequested(1, this.itemsPerPage);
    },
    methods:
    {
      ...mapActions('alerts', ['getAlertList']),
      ...mapActions('alerts', ['removeAlert']),
      rowClicked (item) {
        this.$router.push({name: "EditAlert", params: { alert: item } });
      },
      actionClicked(item, action){
        switch(action.key)
        {
          case 'view':
          {
            this.$router.push({name: "EditAlert", params: { alert: item } });
            break;
          }
          case 'remove':
          {
            EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende remover o alerta '${item.title}'?`, primaryCallback: () =>
            {
              this.loading = true;
              this.removeAlert(item.id);
            }, secondaryButton: "Cancelar"});
            break;
          }
          default:
          {
          throw "Ação não suportada!"; 
          }
        }
    },
    addAlert () 
    {
      router.push({ path: '/alertas/adicionar' });
    },
    updateItemsRequested(currentPage, itemsPerPage)
    {
      this.loading = true;

      this.currentPage = currentPage;
      this.itemsPerPage = itemsPerPage;
      const propertyName = "id";
      const sorterValue = 2; // Descending
      const searchText = '';
      const type = 3;
      const moduleId = this.moduleId.id;

      var input = new InputGetAlerts (currentPage,itemsPerPage, searchText, sorterValue, propertyName, type, moduleId);

      this.getAlertList(input);
         
    },
  },    
   watch: 
    {
      processTask: function(val)
      {
        if(val !== null)
        {
          this.loading = false;
          this.updateItemsRequested(1, this.itemsPerPage);
        }
      },
      listOutput: function()
      {
        this.loading = false;
      },
    }
}
</script>

<style scoped>
.container-fluid
{
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
}

#module-status-title,
#module-status-details
{
    gap: 8px;
}
.skeleton-box
{
  min-height: 25vh;
}
#add-button:hover span
{
  color: whitesmoke;
}
@media only screen and (max-width: 991px) {
  #add-button
  {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    position: relative;
  }
  #add-button > span
  {
    font-size: 28px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
  }
  .skeleton-box
  {
    min-height: 80vh;
  }
}
</style>