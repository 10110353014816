<template>
    <BaseModal v-if="showModal" @detailsClosed="closeConfirmRequested">
        <!-- List view -->
        <div v-if="!selectedEdit" class="modal-body-container">
            <!-- Title -->
            <h4 v-if="isRecord" class="text-center mt-4">Histórico de cash flows</h4>  
            <h4 v-else class="text-center mt-4">Cash flows calendarizados</h4>  
            <p class="text-center" v-if="details">{{details.name}}</p>
            <div v-if="!isRecord" class="col-lg-6 mb-4 p-0 text-left"> 
                <button v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}" class="status-button" @click="status = ACTIVE_STATUS">Ativos</button> 
                <span  v-bind:class="{'font-weight-bold': status == ACTIVE_STATUS}">({{activeCount}})</span> | 
                <button v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}" class="status-button" href="#" @click="status = DISABLED_STATUS">Arquivados</button> 
                <span v-bind:class="{'font-weight-bold': status == DISABLED_STATUS}">({{disabledCount}})</span>
            </div>
            <!-- List -->
            <div class="d-flex flex-column align-items-end px-4">  
                <CTableWrapper ref="table" hover striped :caption="caption" :items="items" :actions="actions" :pages="pages" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" @update-requested="updateItemsRequested" :sorter='{ external: false, resetable: false }' :loading="loading" paginationSubtitle="Cash flows por página">
                </CTableWrapper>
                <CButton type="button" color="primary" class="my-4 btn btn-primary align-self-center" @click="closeConfirmRequested">Fechar</CButton>
            </div>
        </div>
        <!-- Edit view -->
        <div v-else class="modal-body-container">
            <div class="d-flex mt-4 align-items-center justify-content-between">
                <span class="cursor-pointer text-center btn btn-primary button" @click="selectedEdit = null">&lt; Voltar</span>  
                <h4 class="text-center m-0">Editar cash flow</h4>  
            </div>
            <CashFlowEditor :details="details" :baseCashFlow="selectedEdit" :isRecord="isRecord"></CashFlowEditor>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import CTableWrapper from '@/controls/PaginatedTable.vue'
import CashFlowEditor from '@/views/properties/CashFlowEditor.vue'
import { mapState, mapActions } from 'vuex'
import InputGetCashFlows from "@/classes/InputGetCashFlows";
import { UTILS_HELPER } from "@/helpers/utils.js";
import EventBus from '@/events/bus.js';

const DESTINATION_PROPERTY = "Property";
const DESTINATION_MODULE = "Module";

const DISABLED_STATUS = false;
const ACTIVE_STATUS = true;

export default {
    extends: BaseModal,
    components: { BaseModal, CTableWrapper, CashFlowEditor },
    props:{ details: Object, isRecord: Boolean },
    data () {
        return {
            status: ACTIVE_STATUS,
            selectedEdit: null,
            //
            caption: "Cash flows",
            currentPage: 1,
            loading: false,
            itemsPerPage: localStorage.itemsPerPage ?? 10,     
            actions: [
                { key: 'edit', image: 'iconEditWhite.png', imageBig: 'iconEdit.png' },
                this.isRecord ? { key: 'remove', image: 'iconCloseWhite.png', imageBig: 'iconClose.png'} : { key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png' }            
            ],
            // Provide access to consts on HTML
            DISABLED_STATUS: DISABLED_STATUS,
            ACTIVE_STATUS: ACTIVE_STATUS,
        }
    },
    computed:
    {
        isProperty: function()
        {
            return this.details && this.details.ownership;
        },
        ...mapState('cashFlows', ['listOutput']),
        ...mapState('cashFlows', ['processTask']),
         activeCount: function()
        {
            return this.listOutput && this.listOutput.data ? this.listOutput.data.active : 0;
        },
        disabledCount: function()
        {
            return this.listOutput && this.listOutput.data ? this.listOutput.data.inactive : 0
        },
        pages: function()
        {
            return this.listOutput ? this.listOutput.totalPages : 1;
        },
        fields: function()
        {
            var fields = [
                this.isRecord ? { key: 'readableDate', label: "Data", _classes: 'font-weight-bold' } : { key: 'readableFrequency', label: "Frequência", _classes: 'font-weight-bold' },
                { key: 'amount', label: "Valor", _classes: 'font-weight-bold' },
                { key: 'readableType', label: "Tipo", _classes: 'font-weight-bold' },
                { key: 'description', label: "Descrição", _classes: 'font-weight-bold' }
            ];

            return fields;
        },
        items: function()
        {
            var result = [];

            if(this.listOutput && this.listOutput.data || this.listOutput && this.listOutput.data && this.listOutput.data.cashFlows)
            {
                result = this.listOutput && this.listOutput.data && this.listOutput.data.cashFlows ? this.listOutput.data.cashFlows : this.listOutput.data;

                result.forEach(item => {

                    const date = item.createdAt ?? item.moment;
                    item["readableDate"] = UTILS_HELPER.formatDateTime(date);


                    if(item.frequency)
                    {
                        switch(item.frequency)
                        {
                            case "Unique":
                            {
                                item["readableFrequency"] = "Único";
                                break;
                            }
                            case "Weekly":
                            {
                                item["readableFrequency"] = "Semanal";
                                break;
                            }
                            case "Monthly":
                            {
                                item["readableFrequency"] = "Mensal";
                                break;
                            }
                            default:
                            {
                                item["readableFrequency"] = "-";
                                break;
                            }
                        }
                    }

                    switch(item.type)
                    {
                        case "InitialCost":
                        {
                            item["readableType"] = "Custo Inicial";
                            break;
                        }
                        case "Income":
                        {
                            item["readableType"] = "Renda";
                            break;
                        }
                        case "Accountability":
                        {
                            item["readableType"] = "Prestação";
                            break;
                        }
                        case "Costs":
                        {
                            item["readableType"] = "Custos";
                            break;
                        }
                        case "Sales":
                        {
                            item["readableType"] = "Venda";
                            break;
                        }
                        default:
                        {
                            item["readableType"] = "-";
                            break;
                        }
                    }
                    
                });
            }

            return result;
        }
    },
    mounted: function()
    {
        this.clear();
        this.updateItemsRequested(1, this.itemsPerPage);
    },
    methods:
    {
        ...mapActions('cashFlows', ['clear']),
        ...mapActions('cashFlows', ['getList']),
        ...mapActions('cashFlows', ['getListRecords']),
        ...mapActions('cashFlows', ['editStatus']),
        ...mapActions('cashFlows', ['removeCashFlowRecord']),
        closeConfirmRequested()
        {       
            this.$emit("closeConfirmRequested");
        },
        rowClicked: function(item)
        {
            this.actionClicked(item, {key: 'edit'});
        },
        actionClicked(item, action){
            switch(action.key)
            {
                case 'edit':
                {
                    this.selectedEdit = item;
                    break;
                }
                case 'enable':
                {
                    EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende ativar o cash flow registado a '${item.readableDate}'?`, primaryCallback: () =>
                    {
                        this.loading = true;
                        this.editStatus(item.id);
                    }, secondaryButton: "Cancelar"});
                    break;
                }
                case 'disable':
                {
                    EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende arquivar o cash flow registado a '${item.readableDate}'?`, primaryCallback: () =>
                    {
                        this.loading = true;
                        this.editStatus(item.id);
                    }, secondaryButton: "Cancelar"});
                    break;
                }
                case 'remove':
                {
                    EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende remover o cash flow registado a '${item.readableDate}'?`, primaryCallback: () =>
                    {
                        this.loading = true;
                        this.removeCashFlowRecord(item.id);
                    }, secondaryButton: "Cancelar"});
                    break;
                }
                default:
                {
                    throw "The provided action is not supported!"; 
                }
            }
        },
        updateItemsRequested(currentPage, itemsPerPage)
        {
            this.loading = true;

            //Make request      
            this.currentPage = currentPage;
            this.itemsPerPage = itemsPerPage;
            
            var input = new InputGetCashFlows(itemsPerPage, currentPage, 1, "id", "", this.isProperty ? DESTINATION_PROPERTY : DESTINATION_MODULE, this.details.id);
            if(this.isRecord)
            {
                this.getListRecords(input); 
            }
            else
            {
                input.active = this.status;
                this.getList(input); 
            }

        },
    },
    watch: 
    {
        status: function (val) {
        // Update actions
            switch(val) {

                case ACTIVE_STATUS:
                    {
                        this.actions = [];        
                        this.actions.push({ key: 'edit', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
                        this.actions.push({ key: 'disable', image: 'iconFolderWhite.png', imageBig: 'iconFolder.png' });
                        break;
                    }
                case DISABLED_STATUS:
                    {
                        this.actions = [];
                        this.actions.push({ key: 'edit', image: 'iconRightArrowWhite.png', imageBig: 'iconRightArrow.png' });
                        this.actions.push({ key: 'enable', image: 'iconUnarchiveWhite.png', imageBig: 'iconUnarchive.png' });
                        break;
                    }
                    default:
                    {
                        throw "The provided status is not supported!"; 
                    }
            }
            // Update items
            this.$refs.table.reset();
            this.updateItemsRequested(1, this.itemsPerPage);
        },
        listOutput: function()
        {
            this.loading = false;
        },
        processTask: function(val)
        {
            if(val !== null)
            {
                this.loading = false;
                this.selectedEdit = null;
                this.updateItemsRequested(this.currentPage, this.itemsPerPage);
            }
        },
    }
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    }
    .button{
        width: 100px;
    }
</style>