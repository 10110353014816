<template>
    <div class="container d-flex flex-column"> 
        <CRow>
            <!-- Type -->         
            <div class="col-12 my-2" :class="{'col-lg-6': !baseCashFlow || baseCashFlow.frequency, 'col-lg-4': baseCashFlow && !baseCashFlow.frequency}">
                <label class="label-cashFlows">Tipo</label>
                <SingleOptionDropdown v-model="cashFlow.type" :options="typeOptions" :index="1" :is-valid="!submitted || (submitted && !hasError('Type'))"></SingleOptionDropdown>
                <div v-if="submitted && hasError('Type')" class="invalid-feedback">
                    <span v-if="!$v.cashFlow.type.required">É necessário indicar o tipo</span>
                    <span v-if="errors.Type">{{errors.Status}}</span>
                </div>
            </div>
            <!-- Frequency -->         
            <div v-if="!baseCashFlow || baseCashFlow.frequency" class="col-12 col-lg-6 my-2">
                <label class="label-cashFlows">Frequência</label>
                <SingleOptionDropdown v-model="cashFlow.frequency" :options="frequencyOptions" :index="1" :is-valid="!submitted || (submitted && !hasError('Frequency'))"></SingleOptionDropdown>
                <div v-if="submitted && hasError('Frequency')" class="invalid-feedback">
                    <span v-if="!$v.cashFlow.frequency.required">É necessário indicar a frequência</span>
                    <span v-if="errors.Frequency">{{errors.Status}}</span>
                </div>
            </div>
            <!-- Amount -->
            <div class="col-12 my-2" :class="{'col-lg-6': !baseCashFlow || baseCashFlow.frequency, 'col-lg-4': baseCashFlow && !baseCashFlow.frequency}">
                <label class="label-cashFlows">Valor</label>
                <input type="text" v-model="cashFlow.amount" class="form-control" :class="{ 'is-invalid': submitted && hasError('Amount') }"/>
                <div v-if="submitted && hasError('Amount')" class="invalid-feedback">
                    <span v-if="!$v.cashFlow.amount.required">É necessário o valor</span>
                    <span v-if="errors.Amount">{{errors.Amount}}</span>
                </div>
            </div>   
            <!-- Moment -->
            <div v-if="!baseCashFlow || baseCashFlow.moment" class="col-12 col-lg-6 my-2">
                <label class="label-cashFlows">Calendarização</label>
                <input type="datetime-local" v-model="cashFlow.moment" class="form-control" :class="{ 'is-invalid': submitted && hasError('Moment') }"/>
                <div v-if="submitted && hasError('Moment')" class="invalid-feedback">
                    <span v-if="!$v.cashFlow.moment.required">É necessária a calendarização</span>
                    <span v-if="errors.Moment">{{errors.Moment}}</span>
                </div>
            </div>               
            <!-- Created at -->
            <div v-else class="col-12 my-2" :class="{'col-lg-6': !baseCashFlow || baseCashFlow.frequency, 'col-lg-4': baseCashFlow && !baseCashFlow.frequency}">
                <label class="label-cashFlows">Calendarização</label>
                <input type="datetime-local" v-model="cashFlow.createdAt" class="form-control" :class="{ 'is-invalid': submitted && hasError('CreatedAt') }"/>
                <div v-if="submitted && hasError('CreatedAt')" class="invalid-feedback">
                    <span v-if="!$v.cashFlow.createdAt.required">É necessária a calendarização</span>
                    <span v-if="errors.CreatedAt">{{errors.CreatedAt}}</span>
                </div>
            </div>  
            <!-- Description -->
            <div class="col-12 my-2">
                <label class="label-cashFlows">Descrição</label>
                <textarea v-model="cashFlow.description" class="form-control" :class="{ 'is-invalid': submitted && hasError('Description') }"/>
                <div v-if="submitted && hasError('Description')" class="invalid-feedback">
                    <span v-if="!$v.cashFlow.description.required">É necessário a descrição</span>
                    <span v-if="errors.Description">{{errors.Description}}</span>
                </div>
            </div>            
        </CRow>
        <!-- Action button + loading -->  
        <CSpinner v-if="loading" variant="success" small class="align-self-end mt-5"></CSpinner>
        <CButton v-else type="button" color="primary" class="btn align-self-end text-light mt-5" @click="submitClicked">Guardar</CButton>
    </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { mapState, mapActions } from 'vuex'
import { UTILS_HELPER } from "@/helpers/utils.js";
import SingleOptionDropdown from '@/controls/SingleOptionDropdown.vue'

const DESTINATION_PROPERTY = "Property";
const DESTINATION_MODULE = "Module";

export default {
    components: { SingleOptionDropdown },
    validations:
    {
        cashFlow:
        {
            type: { required },
            frequency: { required: requiredIf(function() { return !this.baseCashFlow || this.baseCashFlow.frequency; }) },
            amount: { required },
            moment: { required: requiredIf(function() { return !this.baseCashFlow || this.baseCashFlow.moment; }) },
            createdAt: { required: requiredIf(function() { return this.baseCashFlow && Boolean(this.baseCashFlow.createdAt); }) },
            description: { required }
        }
    },
    props:
    {
        baseCashFlow: 
        {
            type: Object,
            default: null
        },
        details: Object, // property or module
        isRecord: Boolean
    },
    data()
    {
        return {
            submitted: false,
            cashFlow:
            {
                type: this.baseCashFlow ? this.baseCashFlow.type : null,
                frequency: this.baseCashFlow ? this.baseCashFlow.frequency : null,
                amount: this.baseCashFlow ? this.baseCashFlow.amount : 0,
                moment: this.baseCashFlow ? this.baseCashFlow.moment : null,
                createdAt: this.baseCashFlow ? this.baseCashFlow.createdAt : null,
                description: this.baseCashFlow ? this.baseCashFlow.description : '',
            },
            frequencyOptions: [{key: 'Unique', label: 'Único'}, {key: 'Weekly', label: 'Semanal'}, {key: 'Monthly', label: 'Mensal'}]
        }
    },
    methods:{
        ...mapActions('cashFlows', ['addCashFlow']),
        ...mapActions('cashFlows', ['editCashFlowRecord']),
        ...mapActions('cashFlows', ['editCashFlow']),
        hasError(fieldName)
        {
            return this.$v.cashFlow[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        submitClicked()
        {
            if(!this.details)
            {
                throw "The details need to be defined!";
            }

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
           

            if(this.baseCashFlow)
            {
                if(this.isRecord)
                {
                    this.cashFlow.id = this.baseCashFlow.id;
                    this.editCashFlowRecord(this.cashFlow);
                }
                else
                {
                    this.cashFlow.id = this.baseCashFlow.id;
                    this.editCashFlow(this.cashFlow);
                }
            }
            else
            {
                this.cashFlow.destination = this.isProperty ? DESTINATION_PROPERTY : DESTINATION_MODULE;
                this.cashFlow.destinationId = this.details.id;

                this.addCashFlow(this.cashFlow);
            }
        }
    },
    computed:
    {
        isProperty: function()
        {
            console.log(this.details.ownership)
            return this.details && this.details.ownership;
        },
        typeOptions: function()
        {
            var result = [];

            result.push({key: "Costs", label: "Custos"});
            console.log( result)

            if(this.isProperty)
            {
                result.push({key: "Accountability", label: "Prestação"});
            }
            else // Module
            {
                result.push({key: "Income", label: "Renda"});
            }

            result.push({key: "Sales", label: "Venda"});

            return result;
        },
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output,
        }),
        ...mapState('cashFlows', ['processTask']),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        loading: function()
        {
            return this.processTask == null;
        }
    }
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    }
</style>