<template>
    <BaseModal v-if="showModal" @detailsClosed="closeConfirmRequested">
        <!-- List -->
        <div v-if="!selectedEdit" class="modal-body-container">
            <h4 class="text-center mt-4">Histórico de estados</h4>  
            <p v-if="moduleDetails" class="text-center">{{moduleDetails.name}}</p>
            <div class="d-flex flex-column align-items-end px-4">  
                <CTableWrapper ref="table" class="my-5" hover striped :displayNumberAndPagination="false" :caption="caption" :items="items" :actions="actions" :pages="pages" :fields="fields" clickable-rows @row-clicked="rowClicked" @action-clicked="actionClicked" paginationSubtitle="Estados por página">
                </CTableWrapper>
                <CButton type="button" variant="outline" color="primary" class="mb-4 btn btn-primary align-self-center" @click="closeConfirmRequested">Fechar</CButton>
            </div>
        </div>
        <!-- Edit -->
        <div v-else class="modal-body-container">
            <div class="d-flex mt-4 align-items-center justify-content-between">
                <span class="cursor-pointer text-center btn btn-primary button" @click="selectedEdit = null">&lt; Voltar</span>  
                <h4 class="text-center m-0">Editar estado</h4>  
            </div>
            <StatusEditor v-if="moduleDetails" :moduleId="moduleDetails.id" :baseStatus="selectedEdit"></StatusEditor>
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from './BaseModal.vue';
import { UTILS_HELPER } from "@/helpers/utils.js";
import CTableWrapper from '@/controls/PaginatedTable.vue'
import StatusEditor from '@/views/properties/StatusEditor.vue'
import { mapState, mapActions } from 'vuex'
import EventBus from '@/events/bus.js';

export default {
    extends: BaseModal,
    components: { BaseModal, CTableWrapper, StatusEditor },
    props:{ moduleDetails: Object },
    data () {
        return {
            selectedEdit: null,
            //
            caption: "Estados",
            pages: 1,
            itemsPerPage: localStorage.itemsPerPage ?? 10,
            fields: [
                { key: 'readableDate', label: "Data", _classes: 'font-weight-bold' },
                { key: 'readableStatus', label: "Estado", _classes: 'font-weight-bold' },
                { key: 'readableDetails', label: "Detalhes", _classes: 'font-weight-bold' }
            ],      
            actions: [
                { key: 'edit', image: 'iconEditWhite.png', imageBig: 'iconEdit.png' },
                { key: 'remove', image: 'iconCloseWhite.png', imageBig: 'iconClose.png' }
            ],
        }
    },
    computed:
    {
        ...mapState('modules', ['processTask']),
        items: function()
        {
            var result = [];

            if(this.moduleDetails && this.moduleDetails.statuses)
            {
                result = this.moduleDetails.statuses;

                result.forEach(item => {
                    item["readableDate"] = UTILS_HELPER.formatDate(item.createdAt);

                    switch(item.status)
                    {
                        case "Rented":
                        {
                            item["readableStatus"] = "Arrendado";
                            item["readableDetails"] = [item.personName, item.personEmail, item.personPhoneNumber].join(", ");
                            break;
                        }
                        case "AirBnB":
                        {
                            item["readableStatus"] = "AirBnb";
                            item["readableDetails"] = item.url;
                            break;
                        }
                        case "NoIncome":
                        {
                            item["readableStatus"] = "Sem rendimento";
                            item["readableDetails"] = '-';
                            break;
                        }
                    }
                });
            }

            return result;
        },
    },
    methods:
    {
        ...mapActions('modules', ['removeStatus']),
        closeConfirmRequested()
        {       
            this.$emit("closeConfirmRequested");
        },
        rowClicked: function(item)
        {
            this.actionClicked(item, {key: 'edit'});
        },
        actionClicked(item, action){
            switch(action.key)
            {
                case 'edit':
                {
                    this.selectedEdit = item;
                    break;
                }
                case 'remove':
                {
                    EventBus.$emit("warning-alert-requested", { message: `Tem a certeza que pretende remover o estado registado a '${item.readableDate}'?`, primaryCallback: () =>
                    {
                        this.removeStatus(item.id);
                    }, secondaryButton: "Cancelar"});
                    break;
                }
                default:
                {
                    throw "The provided action is not supported!"; 
                }
            }
        },
    },
    watch: 
    {
        processTask: function(val)
        {
            if(val)
            {
                this.selectedEdit = null;
            }
        }
    }
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    }
    .button{
    width: 100px;
    }
</style>